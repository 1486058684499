"use client";
import Script from "next/script";
import { GTM_ID } from "./googletagmamager";
import { activateAmplitude } from "./amplitude";
import { useEffect } from "react";
import * as analytics from "@/analytics/index";

declare global {
  interface Window {
    activateAmplitude?: () => void;
  }
}

function ConsentAndTrackingScripts() {
  useEffect(() => {
    analytics.load();
  }, []);
  return (
    <>
      <AmplitudeActivationScript />
      <GoogleTagManagerWithConsent />
    </>
  );
}

function GoogleTagManagerWithConsent() {
  return (
    <>
      <Script id="gtm-consent" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }

          gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000 // milliseconds to wait for the update
          });

          gtag("set", "ads_data_redaction", true);
        `}
      </Script>

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${GTM_ID}');
        `}
      </Script>
    </>
  );
}

function AmplitudeActivationScript() {
  if (typeof window !== "undefined") {
    // The script will only be exectued in the browser, and `window` will be present
    window.activateAmplitude = activateAmplitude;
  }

  return (
    <Script
      id="amplitude"
      strategy="afterInteractive"
      data-usercentrics="Amplitude"
      //  text/plain with be turned to test/javascript by usercentrics if the user consents to tracking
      //  See https://docs.usercentrics.com/#/direct-implementation-guide?id=change-script-type-textjavascript-becomes-textplain
      type="text/plain"
    >
      {`window.activateAmplitude();`}
    </Script>
  );
}

export { ConsentAndTrackingScripts };
